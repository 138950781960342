import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import AboutUs from '../../../semantic-ui/assets/images/AboutUs.jpg';
import PageTitle from '../../../components/PageTitle';
import { Element } from "react-scroll/modules";

const About = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <Image fluid src={AboutUs} alt="AboutUs"/>
        </Grid.Column>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <Element name="AboutUs">
            <PageTitle title="ABOUT US" subtitle="WHO WE ARE" titlecolor="textRed"/>
          </Element>
          <p>We are a friendly and helpful same day courier company with vehicles across the country available to
            collect within the 60-90 minutes of your call.
            We have years of experience delivering around the UK & Europe and a passion for executing the perfect
            delivery time after time, be confident in an on-time delivery every time with us and above all we will
            always go that extra mile to assist where we can.
          </p>
          <p>
            We are ambitious courier company who successfully deliver any kind of packages whether small or huge,
            medical or technical, legal or fragile and list is never ending. We feel proud in providing exceptional
            services to our customers regardless of how big or small businesses they are. We are reliable, time critical
            and safe transport for delivery across United Kingdom and Europe.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default About;
