import React from 'react'
import {Icon, SemanticICONS} from "semantic-ui-react";
import "./features.css"

interface FeatureProps {
  header: string;
  children: React.ReactNode;
  iconName: SemanticICONS;
}


const Feature = ({header, children, iconName}: FeatureProps) => {

  return (
    <div className="single_feature">
      <div className="feature_icon">
        <Icon name={iconName}/>
      </div>
      <div className="feature_content">
        <div className="feature_title">
          <h4>{header}</h4>
        </div>
        <p>{children}</p>
      </div>
    </div>
  )
};

export default Feature;
