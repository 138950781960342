import React, { useEffect, useState } from 'react';
import { Link as LinkTo, NavLink, useHistory } from 'react-router-dom'
import { Link } from 'react-scroll'
import { Button, Container, Icon, Menu, Responsive } from 'semantic-ui-react';
import logo from '../../semantic-ui/assets/images/logo.svg';
import { useSession } from '../../hooks';
import BtnAnimated from '../BtnAnimated';
import './header.css'
import { Path } from '../../routes';

interface IMenuItem {
  to: string;
  text: string;
  internal?: boolean;
}

interface IRenderMenuProps {
  onClick?(): any
}

export default function Header() {
  const [show, setShow] = React.useState(false);
  const [isTop, setIsTop] = useState(true);
  const handleScroll = (event: any) => {
    const isTop = window.scrollY < 100;
    setIsTop(isTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isTop]);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Responsive maxWidth={991} style={{ width: '100%', float: 'left' }}>
        <header className={`headerTop ${isTop ? '' : 'fix'}`}>
          <NavLink exact className="floatLeft" to="/" style={{ minHeight: '70px' }}>
            <img className="logo" src={logo} alt="logo"/>
          </NavLink>

          <Button className="floatRight bars" icon onClick={toggleShow}>
            <Icon name='bars'/>
          </Button>

          {
            show &&
            <Menu className="mobile" floated="right" stackable>
              <RenderMenu onClick={toggleShow}/>
              <Menu.Item>
                <AuthBtn/>
              </Menu.Item>
            </Menu>
          }

        </header>
      </Responsive>
      <Responsive minWidth={992}>
        <header className={`headerTop ${isTop ? '' : 'fix'}`}>
          <Container>
            <NavLink exact to="/" style={{ minHeight: '70px' }}>
              <img className="logo" src={logo} alt="logo"/>
            </NavLink>
            <Menu floated="right" stackable>
              <RenderMenu/>
            </Menu>
          </Container>

          <AuthBtn/>
        </header>
      </Responsive>
    </>
  )
};


function RenderMenu(props: IRenderMenuProps) {
  const { user } = useSession();
  const isAdmin = user?.id > 0;
  const options: Array<IMenuItem> = isAdmin
    ? [
      {
        to: Path.AdminQuotes,
        text: 'QUOTES',
      },
      {
        to: Path.AdminVehicle,
        text: 'VEHICLES'
      },
      {
        to: Path.AdminUsers,
        text: 'USERS'
      }
    ]
    : [
      {
        to: 'Home',
        text: 'HOME',
        internal: true,
      },
      {
        to: 'Services',
        text: 'SERVICES',
        internal: true,
      },
      {
        to: 'AboutUs',
        text: 'ABOUT US',
        internal: true,
      },

      {
        to: 'ContactUs',
        text: 'CONTACT US',
        internal: true,
      }
    ];

  return (
    <>
      {
        options.map((item, idx) => (
          <Menu.Item key={idx}>
            {
              item.internal
                ? (
                  <Link to={item.to} as="a" spy smooth duration={500} onClick={props.onClick}>
                    {item.text}
                  </Link>
                )
                : (
                  <LinkTo to={item.to} onClick={props.onClick}>
                    {item.text}
                  </LinkTo>
                )
            }
          </Menu.Item>
        ))
      }
    </>
  )
}


function AuthBtn() {
  const { user, logOut } = useSession();
  const history = useHistory();
  const hasSession = !!(user && user.id);

  const label = hasSession ? 'LOG OUT' : 'LOGIN';
  const icon = hasSession ? 'sign-out' : 'sign-in';
  const handleClick = async () => {
    if (hasSession) {
      await logOut();
    }
    history.push('/login');
  };

  return (
    <BtnAnimated className="btnTopFix" label={label} icon={icon} onClick={handleClick}/>
  );
}

