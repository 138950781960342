import uuidv4 from 'uuid/v4';

export enum ItemKey {
  AppId = 'APP_ID',
  AuthToken = 'AUTH_TOKEN'
}

class LocalStorage {

  static appId = (): string | null => {
    let id = LocalStorage.getItem(ItemKey.AppId);
    if (id) {
      return id;
    }

    id = uuidv4();
    LocalStorage.setItem(ItemKey.AppId, id);
    return id;
  };


  static getItem = (key: ItemKey): string | null => {
    return window.localStorage.getItem(key);
  };

  static setItem = (key: ItemKey, value: string) => {
    window.localStorage.setItem(key, value)
  };

  static removeItem = (key: ItemKey) => {
    window.localStorage.removeItem(key);
  };

  static clear = () => {
    window.localStorage.removeItem(ItemKey.AuthToken);
  };
}

export default LocalStorage;




