import React from 'react';
import Calendar from 'react-datepicker';

import './styles.css'

export interface DatePickerProps {
  value: string;
  minDate?: Date;
  placeholder: string;
  hasError: boolean;

  onChange(newVal: string): void;
}


export default function DatePicker(props: DatePickerProps): any {
  // date format for en-GB = dd/mm/yyyy
  const dt = (props.value && new Date(props.value)) || undefined;
  return (
    <Calendar
      placeholderText={props.placeholder}
      selected={dt}
      minDate={props.minDate}
      dateFormat="dd/MM/yyyy h:mm aa"
      timeFormat="hh:mm aa"
      timeIntervals={15}
      showTimeSelect

      onChange={(dt: Date) => {
        props.onChange(dt.toISOString());
      }}
    />
  )
}
