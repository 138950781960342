import { gql } from 'apollo-boost'

export const SetLogin = gql`
  mutation Login($email: String!, $pwd: String!){
    login(email: $email, pwd: $pwd){
      id
      email
      name
      roles {
        roleId
      }
      active
      locked
      authToken
    }
  }
`;

export const GetSessionUser = gql`
  query SessionUser{
    me {
      id
      email
      name
      roles {
        roleId
      }
      active
      locked
    }
  }
`;

export const GetUsers = gql`
  query Users($page: Page!){
    users(page: $page) {
      total
      items {
        id
        name
        email
        active
        locked
        roles {
          roleId
        }
      }
    }
  }
`;
