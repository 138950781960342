import React from 'react';
import { useMutation } from '@apollo/react-hooks'
import { Card, CardContent, Container, Grid, Icon, Step } from 'semantic-ui-react';
import * as yup from 'yup';
import DataForm, { DataFormFieldType } from 'semantic-data-form';
import { FormikHelpers } from 'formik';
import { ContactUsRequest } from '../../gql/contactUs';

import { IContactUS } from '../../types';
import { toast } from "../ToastContainer";

import './styles.css'


export default function ContactUs() {
  const [submit] = useMutation(ContactUsRequest);

  return (
    <>
      <Container className="mt40">
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16} mobile={16} textAlign="left">
              <div className="enquiryForm boxShadowNone">
                <DataForm
                  fieldGroups={[
                    {
                      fields: [
                        {
                          name: 'name',
                          placeholder: 'Your Name',
                          type: DataFormFieldType.Text,
                          hideErrorLabel: true
                        },
                        {
                          name: 'email',
                          placeholder: 'Your Email',
                          type: DataFormFieldType.Email,
                          hideErrorLabel: true
                        },
                      ]
                    },
                    {
                      fields: {
                        name: 'query',
                        placeholder: 'Your query',
                        type: DataFormFieldType.TextArea,
                        hideErrorLabel: true
                      },
                    }
                  ]}
                  initialValues={{
                    name: '',
                    email: '',
                    query: '',
                  }}
                  validationSchema={yup.object().shape({
                    name: yup.string().required('Required'),
                    email: yup.string().email('Invalid email').required('Required'),
                    query: yup.string().required('Required'),
                  })}
                  submitText="SUBMIT"
                  onSubmit={async (values: IContactUS, helpers: FormikHelpers<IContactUS>) => {
                    await submit({
                      variables: { input: values }
                    });

                    helpers.resetForm();
                    // toast
                    toast({
                      title: 'Contact Us',
                      description: 'Thank you, we have successfully received your request and will get back to you very soon.',
                      type: 'success',
                      icon: 'check circle'
                    })
                  }}
                  onSubmitError={(err: string) => {
                    toast({
                      title: 'Contact Us',
                      description: err.toString(),
                      type: 'error',
                      icon: 'exclamation circle'
                    });
                  }}
                />
              </div>
            </Grid.Column>

            <Grid.Column computer={8} tablet={16} mobile={16} textAlign="left">
              <Card fluid className="mt30">
                <CardContent>
                  <Step.Group fluid vertical>
                    <Step className="borderNone">
                      <Icon name='map marker alternate'/>
                      <Step.Content>
                        <Step.Title> Head Office</Step.Title>
                        <Step.Description>1 AginCourt Villas, Uxbridge Road, Uxbridge UB10 0NX</Step.Description>
                      </Step.Content>
                    </Step>

                    <Step className="borderNone">
                      <Icon name='phone'/>
                      <Step.Content>
                        <Step.Title>Call Us</Step.Title>
                        <Step.Description>
                          <a href="tel:07521505661">07521-505661</a>, <a href="tel:07715881799">07715-881799</a>
                        </Step.Description>
                      </Step.Content>
                    </Step>

                    <Step className="borderNone">
                      <Icon name='mail'/>
                      <Step.Content>
                        <Step.Title>Email Us</Step.Title>
                        <Step.Description>
                          <a href="mailto:Sohi Logistics<info@sohilogistics.co.uk>">info@sohilogistics.co.uk</a>
                        </Step.Description>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                </CardContent>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}
