import * as React from 'react';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import "./Style/css/style.css"
import { Dimmer, Loader } from 'semantic-ui-react';
import ErrorBoundary from './components/ErrorBoundary';

import client from './gql/client';

import routes, { RouteItem } from './routes';
import ScrollToTop from './components/ScrollToTop';

const loadingRoute = (
  <Dimmer active page inverted>
    <Loader content="Loading..." inverted/>
  </Dimmer>
);


function RouteWithSubRoutes(route: RouteItem) {
  return (
    <>
      <Route
        exact={route.exact}
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component
            {...props}
            // @ts-ignore
            routes={route.routes}
            topDrawerRoutes={route.topDrawerRoutes}
            modalRoutes={route.modalRoutes}
          />
        )}
      />
    </>
  );
}

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop/>
        <ErrorBoundary>
          <Suspense fallback={loadingRoute}>
            <Switch>
              {
                routes.map((route, idx) => <RouteWithSubRoutes key={idx} {...route} />)
              }
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </ApolloProvider>
  )
};

export default App;
