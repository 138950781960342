import { useQuery } from '@apollo/react-hooks';
import { GetSessionUser } from '../gql/account';
import localStorage from '../helpers/localStorage';

export interface SessionData {
  data: {
    me: {
      id: number;
      name: string;
      email: string;
      roles: [number];
    }
  }
}


export default function useSession() {
  const { loading, error, data, client } = useQuery(GetSessionUser);
  return {
    loading,
    error,
    user: data?.me,
    hasUser: !!data?.me?.id,
    client,
    logOut: async () => {
      localStorage.clear();
      await client.clearStore()
    }
  }
}


