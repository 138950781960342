import React from 'react';
import { Container, Grid, Image, List, Menu } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-scroll/modules';
import './footer.css'
import fb from '../../semantic-ui/assets/images/fb.png';
import insta from '../../semantic-ui/assets/images/insta.png';
import pp from '../../semantic-ui/assets/images/pp.png';
import twitt from '../../semantic-ui/assets/images/twitt.png';


export default function Footer() {
  const loc = useLocation();
  const isHomePage = loc.pathname === '/';
  const dt =new Date();

  return (
    <>
      <footer className="footer mt15">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center">
                <List horizontal relaxed className="mt30">
                  <List.Item>
                    <a href="/"><Image src={fb}/></a>
                  </List.Item>
                  <List.Item>
                    <a href="/"><Image src={insta}/></a>
                  </List.Item>
                  <List.Item>
                    <a href="/"><Image src={pp}/></a>
                  </List.Item>
                  <List.Item>
                    <a href="/"><Image src={twitt}/></a>
                  </List.Item>
                </List>
              </Grid.Column>

              {
                isHomePage && (
                  <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center">
                    <Menu text className="menuFooter">
                      <Menu.Item>
                        <Link to="Home" as="a" spy smooth duration={500}>
                          HOME
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="AboutUs" as="a" spy smooth duration={500} offset={-120}>
                          ABOUT US
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="Services" spy smooth duration={500} offset={-120}>
                          SERVICES
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="ContactUs" spy smooth duration={500} offset={-120}>
                          CONTACT US
                        </Link>
                      </Menu.Item>

                    </Menu>
                  </Grid.Column>
                )
              }

            </Grid.Row>
          </Grid>
        </Container>

      </footer>
      <div className="footerDown textCenter">
        <p>&copy;{dt.getFullYear()} Sohi Logistics. All Rights Reserved.</p>
      </div>
    </>
  )
};
