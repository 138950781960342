import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import './style.css';
import client1 from '../../semantic-ui/assets/images/client1.png'

const AutoplaySlider = withAutoplay(AwesomeSlider);

const ViewSlider = () => {
  return (
    <AutoplaySlider
      animation="cubeAnimation"
      play={true}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={6000}
    >
      <div className="sliderContent">
        <img src={client1} alt="#"/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt, ut labore etdolore
          magna aliqua. Quis ipsum suspendisse ultrices gravida. ut labore etdolore magna aliqua. Quis ipsum suspendisse
          ultrices gravida.
          Quis ipsum suspendisse ultrices gravida.</p>
      </div>
      <div className="sliderContent">
        <img src={client1} alt="#"/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt, ut labore
          etdolore
          magna aliqua. Quis ipsum suspendisse ultrices gravida. ut labore etdolore magna aliqua. Quis ipsum suspendisse
          ultrices gravida.
          Quis ipsum suspendisse ultrices gravida.</p>
      </div>
      <div className="sliderContent">
        <img src={client1} alt="#"/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt, ut labore
          etdolore
          magna aliqua. Quis ipsum suspendisse ultrices gravida. ut labore etdolore magna aliqua. Quis ipsum suspendisse
          ultrices gravida.
          Quis ipsum suspendisse ultrices gravida.</p>
      </div>
      <div className="sliderContent">
        <img src={client1} alt="#"/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt, ut labore
          etdolore
          magna aliqua. Quis ipsum suspendisse ultrices gravida. ut labore etdolore magna aliqua. Quis ipsum suspendisse
          ultrices gravida.
          Quis ipsum suspendisse ultrices gravida.</p>
      </div>
    </AutoplaySlider>
  )
};

export default ViewSlider;
