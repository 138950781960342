import React from "react";


export interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  titlecolor?: string;
  subtitlecolor?: string;
}

export default function MainPageTitle(props: PageHeaderProps) {

  return (
    <>
      <h4 className={`subTitle mt50 mb0 ${props.subtitlecolor || ''}`}>
        {props.subtitle}
      </h4>
      <h2  className={`titleMain mt0 mb10 ${props.titlecolor || ''}`}>
        {props.title}
      </h2>
    </>
  )
}
