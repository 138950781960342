import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import Header from '../../components/Header/Header';
import AboutUs from './AboutUs/About';
import OurServices from './OurServices/OurServices';
import PageTitle from '../../components/PageTitle/PageTitle';
import Footer from '../../components/Footer';
import './style.css'
import { Element } from 'react-scroll/modules';
import NewQuote from '../Admin/Quotes/NewQuote';
import ToastContainer from '../../components/ToastContainer'
import Feature from '../../components/Feature';
import ViewSlider from '../../components/ViewSlider';
import ContactUs from '../../components/ContactUs'

const Home = () => (
  <>
    <div className="banner">
      <Header/>
      <Container>
        <Element name="Home">
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} tablet={6} mobile={16} floated='left' textAlign="center">
                <div className="bannerContent">
                  <h2 className="txtWhite">Same Day <span className="txtHighlight">Delivery</span></h2>
                  <p className="txtWhite">
                    Looking for safe and secure deliveries of your precious items. We provide safe and secure deliveries
                    all over the United Kingdom. With our fully tracked vehicles we will deliver your goods safely with
                    our trusted drivers same day
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column
                computer={8} tablet={10} mobile={16} floated='left' textAlign="center"
                style={{ zIndex: 1 }}
              >
                <h3 className="txtWhite f26 " style={{ marginTop: '2%' }}>Need to make an Enquiry?</h3>
                <NewQuote/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Element>
      </Container>
    </div>
    <div className="clearFix"/>
    <div className="servicesBg section_padding">
      <Container style={{ paddingBottom: '120px' }}>
        <Grid stackable columns='equal'>
          <Grid.Row>
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <Element name="Services">
                <PageTitle
                  title="OUR SERVICES"
                  subtitle=" WE PROVIDE SERVICES" titlecolor="textRed"/>
              </Element>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <OurServices
              header="Same Day Courier Service Nationwide"
              bgImg={require('../../semantic-ui/assets/images/flight.jpg')}
              hoverImg={require('../../semantic-ui/assets/images/flight1.jpg')}
            >
              Looking for safe and secure deliveries of your precious items? We provide safe and secure deliveries all
              over the United Kingdom and Europe. With our fully tracked vehicles we will deliver your goods safely with
              our trusted drivers the same day
            </OurServices>

            <OurServices
              header="Dedicated Direct Drive Delivery"
              bgImg={require('../../semantic-ui/assets/images/truck1.jpg')}
              hoverImg={require('../../semantic-ui/assets/images/truck.jpg')}
            >
              If you prefer that only your goods need to be in one of our vans, then look no further. Anything from
              small parcels to pallets, we will allocate a van for your specific needs which could be going anywhere to
              UK or Europe. We got that covered.
            </OurServices>
            <OurServices
              header="Overnight Deliveries"
              bgImg={require('../../semantic-ui/assets/images/ship1.jpg')}
              hoverImg={require('../../semantic-ui/assets/images/ship.jpg')}
            >
              We offer a range of overnight dedicated deliveries as well. Our driver will collect from time you have
              specified while booking and will deliver to its destination in the next day on the given time.
            </OurServices>
            <OurServices
              header="Time Critical Deliveries"
              bgImg={require('../../semantic-ui/assets/images/time.jpg')}
              hoverImg={require('../../semantic-ui/assets/images/time1.jpg')}
            >
              If you need your goods to be moved urgently, we are there no matter what time and day it is.
            </OurServices>

            <OurServices
              header="Wait & Return Services"
              bgImg={require('../../semantic-ui/assets/images/wait&return.jpg')}
              hoverImg={require('../../semantic-ui/assets/images/wait&return1.jpg')}
            >
              We also offer wait and return services which means our driver will deliver your goods and will wait to
              collect goods from there and bring them back to you.

            </OurServices>
          </Grid.Row>
        </Grid>
      </Container>
      <div className="clearFix"/>
      <div className="feature_area dark_bg reveal animated section_padding">
        <Grid stackable columns='equal'>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16} mobile={16} className="features_bg">
              <div className="feature_wrapper section_padding">
                <PageTitle
                  title="OUR BEST FEATURES"
                  subtitle="WHY CHOOSE US" subtitlecolor="textWhite" titlecolor="textWhite"/>

                <Feature iconName="random" header="Flexibility is our strength">
                  We will be flexible according to your needs
                </Feature>
                <Feature iconName="shipping fast" header="We are fast">
                  Goods will be delivered to destination in the quickest possible time
                </Feature>
                <Feature iconName="pound sign" header="Competitive price">
                  Competitive prices without compromising our services.
                </Feature>
                <Feature iconName="text telephone" header="Always reachable">
                  We are open 24/7 365 days so always in your reach.
                </Feature>
                <Feature iconName="file text" header="Proof of delivery">
                  We will send you proof of delivery as soon as delivery is made by email and hard copy by the post if
                  required.
                </Feature>

              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="chooseUs"/>
      </div>
    </div>
    <div className="clearFix"/>
    <Container className="section_padding">
      <AboutUs/>
    </Container>
    <div className="clearFix"/>
    <div className="ViewSliderBg textCenter section_padding">
      <PageTitle
        title="TESTIMONIALS"
        subtitle="WHAT OUR CLIENTS SAY" subtitlecolor="textWhite" titlecolor="textWhite"/>
      <ViewSlider/>
    </div>
    <div className="clearFix"/>
    <div className="textCenter section_padding">
      <Element name="ContactUs">
        <PageTitle
          title="CONTACT US"
          subtitle="GET IN TOUCH" titlecolor="textRed"/>
      </Element>
      <ContactUs/>
    </div>
    <div className="clearFix"/>
    <Footer/>
    <ToastContainer/>
  </>
);

export default Home;
