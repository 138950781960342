import Home from './containers/Home';
import * as React from 'react';
import { ComponentPropsWithRef, ElementType, ExoticComponent } from 'react';

export enum Path {
  Home = '/',
  Login = '/login',
  Admin = '/admin',
  AdminVehicle = '/admin/vehicles',
  AdminQuotes = '/admin/quotes',
  AdminUsers = '/admin/users',
  TermsAndConditions = '/terms-and-conditions'
}

export interface Route {
  path: string;
  title?: string;
  component: React.ComponentClass | React.FunctionComponent | ExoticComponent<ComponentPropsWithRef<ElementType>>;
  exact?: boolean;
}

export interface RouteItem extends Route {
  routes?: Array<Route>
  topDrawerRoutes?: Array<Route>
  modalRoutes?: Array<Route>
}

const routes: Array<RouteItem> = [
  {
    path: Path.Home,
    component: Home,
    exact: true
  },
  {
    path: Path.Login,
    component: React.lazy(() => import('./containers/Login')),
    exact: true
  },
  {
    path: Path.Admin,
    component: React.lazy(() => import('./containers/Admin')),
    routes: [
      {
        path: Path.AdminQuotes,
        component: React.lazy(() => import('./containers/Admin/Quotes')),
        exact: false
      },
      {
        path: Path.AdminVehicle,
        component: React.lazy(() => import('./containers/Admin/Vehicles')),
        exact: false
      },
      {
        path: Path.AdminUsers,
        component: React.lazy(() => import('./containers/Admin/Users')),
        exact: false
      },

      {
        path: '*',
        component: React.lazy(() => import('./containers/NoMatch')),
        exact: true
      },
    ],
    topDrawerRoutes: [
      {
        path: `${Path.AdminVehicle}/create`,
        title: 'Add New Vehicle',
        component: React.lazy(() => import('./containers/Admin/Vehicles/NewVehicle')),
        exact: true
      },

      {
        path: `${Path.AdminVehicle}/edit/:id`,
        title: 'Edit New Vehicle',
        component: React.lazy(() => import('./containers/Admin/Vehicles/EditVehicle')),
        exact: true
      },

      {
        path: `${Path.AdminQuotes}/edit/:id`,
        title: 'Edit Quote',
        component: React.lazy(() => import('./containers/Admin/Quotes/EditQuote')),
        exact: true
      },
    ]
  },
  {
    path: Path.TermsAndConditions,
    component: React.lazy(() => import('./containers/TermsAndConditions')),
    exact: true
  },
  {
    path: '*', component: React.lazy(() => import('./containers/NoMatch')),
    exact: true
  },
];


export default routes;


