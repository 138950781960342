import { gql } from 'apollo-boost'


export const SubmitQuote = gql`
  mutation SubmitQuote($pickupCode: String!,
    $pickupOn: String!,
    $dropCode: String!,
    $dropOn: String!,
    $vehicleId: ID!,
    $email: String!,
    $note: String,
    $isAgree: Boolean!) {
    quote(detail: {
      pickupCode: $pickupCode,
      pickupOn: $pickupOn,
      dropCode: $dropCode,
      dropOn: $dropOn,
      vehicleId: $vehicleId,
      email: $email,
      note: $note,
      isAgree: $isAgree
    })
  }
`;


export const GetQuotes = gql`
  query quotes($page: Page!) {
    quotes(page: $page) {
      total
      items {
        id
        pickupCode
        pickupOn
        dropCode
        dropOn
        email
        note
        status
        vehicleId
      }
    }
  }
`;

export const GetQuoteById = gql`
  query quote($id: ID!) {
    quote(id: $id) {
      id
      pickupCode
      pickupOn
      dropCode
      dropOn
      email
      note
      status
      vehicleId
    }
  }
`;

export const SaveQuote = gql`
  mutation quotes($input: SaveQuoteInput!) {
    saveQuote(input: $input)
  }
`;
