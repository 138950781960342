import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

export interface BtnAnimatedProps {
  label: string;
  icon: SemanticICONS;
  className?: string;
  to?: string;

  onClick?(): void
}

export default function BtnAnimated(props: BtnAnimatedProps) {
  const h = useHistory();

  const handleClick = () => {
    if (props.onClick) props.onClick();
    if (props.to) h.push(props.to);
  };

  return (
    <Button animated size="small" primary className={props.className} onClick={handleClick}>
      <Button.Content visible>{props.label}</Button.Content>
      <Button.Content hidden>
        <Icon name={props.icon}/>
      </Button.Content>
    </Button>

  )
}
