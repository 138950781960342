import React, {useState} from 'react'
import {Card, Grid, Image} from "semantic-ui-react";
import "./OurServices.css"

interface OurServicesProps {
  header: string;
  bgImg: string
  hoverImg: string
  children: React.ReactNode
}


const OurServices = ({header, children, hoverImg, bgImg}: OurServicesProps) => {
  const [img, setImg] = useState(bgImg);

  return (
    <Grid.Column computer={5} tablet={8} mobile={16} className="textCenter">
      <Card
        className="services"
        onMouseOver={() => {
          setImg(hoverImg)
        }}
        onMouseLeave={() => {
          setImg(bgImg)
        }}
      >
        <Image
          fluid
          src={img}
          alt="#"/>
        <Card.Content textAlign="center">

          <Card.Header as="h3">{header}</Card.Header>
          <Card.Description>{children}</Card.Description>
        </Card.Content>
      </Card>
    </Grid.Column>

  )
};

export default OurServices;
