import {gql} from 'apollo-boost'

export const GetVehicles = gql`
  query Vehicles($page: Page!){
    vehicles(page:$page) {
      total
      items {
        id
        title
        description
        img {
          key
        }
      }
    }
  }
`;

export const GetVehicle = gql`
  query Vehicle($id: ID!){
    vehicle(id:$id) {
      id
      title
      description
      img {
        key
      }
    }
  }
`;

export const SaveVehicle = gql`
  mutation Save($id: ID, $title: String!, $description: String!, $image: FileInput) {
    saveVehicle(vehicle: { id: $id, title: $title, description: $description, image: $image  } ) {
      id
      title
      description
      img {
        name
        key
      }
    }
  }
`;


export const DeleteVehicle = gql`
  mutation Delete($id: ID!) {
    deleteVehicle(id: $id)
  }
`;
