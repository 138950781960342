import React from 'react';

export enum VehicleImageSize {
  Small = 'small',
  Medium = 'medium',
  Default = ''
}

export interface VehicleImageProps {
  fileName: string,
  size: VehicleImageSize
}

export default function VehicleImage(props: VehicleImageProps) {
  const parts = props.fileName?.split('.') || [];
  let src = '';
  if (parts.length > 1) {
    src = `${process.env.REACT_APP_ASSETS_BUCKET}/${parts[0]}-${props.size}.${parts[1]}`;
  }

  return (
    <img alt="vehicle" src={src} style={{width:'auto',maxHeight:'40px',borderRadius:'50%',margin:'15px 15px 0',verticalAlign:'middle'}}/>
  )
}
