import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from 'apollo-boost';
import localStorage, { ItemKey } from "../helpers/localStorage";


const authLink = new ApolloLink((operation, forward) => {
  const appId = localStorage.appId();
  const token = localStorage.getItem(ItemKey.AuthToken);

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      'x-app-id': appId,
      authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const host = process.env.NODE_ENV === 'production'
  ? 'https://api.sohilogistics.co.uk'
  : 'http://127.0.0.1:5000';

const httpLink = new HttpLink({
  uri: `${host}/query`,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});

export default client;
